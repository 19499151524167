import {useTranslation} from "react-i18next";
import {DataTable} from "components/DataTable";
import {requestProductList} from "../../apis";
import {useNavigate} from "react-router-dom";

export const ProductListPage = () => {
    const {t} = useTranslation(["page"]);
    const navigate = useNavigate();

    const Header = (
        <article className={"fluid"} key={"page_productlist-section_header"}>
            <header>
                <h1>{t("page:manage_products")}</h1>
            </header>
        </article>
    );

    const ProductList = (
        <article className={"fluid"} key={"page_productlist-section_productlist"}>
            <DataTable
                fetchApi={requestProductList}
                formatters={{
                    [t("page:name")]: (data) => data.name,
                    [t("orginal_price")]: (data) => data.original_price,
                    [t("sales_price")]: (data) => data.sales_price,
                    [t("test_set_count")]: data => data.test_sets.length,
                    [t("created_at")]: (data) => data.created_at,
                }}
                onDataClick={(data) => navigate(`/products/${data.uid}`)}
                keyName={"uid"}
            />
        </article>
    );

    return [Header, ProductList];
}
