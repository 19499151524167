import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";
import {requestUserList} from "apis";
import {DataTable} from "components/DataTable";

export const UserListPage = () => {
    const { t } = useTranslation(["page"]);
    const navigate = useNavigate();

    const Header = (
        <article className={"fluid"} key={"page_userlist-section_header"}>
            <header>
                <h1>{t("page:manage_users")}</h1>
            </header>
        </article>
    );

    const UserList = (
        <article className={"fluid"} key={"page_userlist-section_userlist"}>
            <DataTable
                fetchApi={requestUserList}
                formatters={{
                    [t("page:email")]: (data) => data.email,
                    [t("page:name")]: (data) => `${data.first_name} ${data.last_name}`,
                    [t("page:is_active")]: (data) => data.is_active ? (
                        <div className={"d-flex flex-row justify-content-center align-items-center text-success"}>
                            <FaCircleCheck />
                        </div>
                    ) : (
                        <div className={"d-flex flex-row justify-content-center align-items-center text-danger"}>
                            <MdCancel />
                        </div>
                    ),
                    [t("page:date_joined")]: (data) => data.date_joined,
                    [t("page:last_login")]: (data) => data.last_login,
                }}
                onDataClick={(data) => navigate(`/users/${data.uid}`)}
                keyName={"uid"}
            />
        </article>
    );

    return [Header, UserList];
}
