import {CgProfile} from "react-icons/cg";
import {useAuth} from "../hooks";

export function Navbar() {
    const { user } = useAuth();

    return (
        <section className="search-and-user">
            <form>
            </form>
            <div className="admin-profile">
                <span className="greeting">Hello, {user.first_name}</span>
                <div className="notifications">
                {/*    <span className="badge">1</span>*/}
                    <CgProfile aria-hidden="true" />
                </div>
            </div>
        </section>
    );
}
