import React from "react";
import "../DataTable/data-table.css";

export function DataPropertyView({data, formatters}) {
    const dataKeys = Object.keys(formatters);

    return (
        <div className={"d-flex flex-column gap-3"} style={{ overflowX: "auto", scrollbarWidth: "thin" }}>
            {dataKeys.map((dataKey) => (
                <div className={"d-flex flex-row flex-wrap text-no-wrap border-bottom"} key={dataKey}>
                    <h5 className={"flex-1"}>{dataKey.split("_").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ")}</h5>
                    <div className={"flex-3"}>{formatters[dataKey](data)}</div>
                </div>
            ))}
        </div>
    )
}
