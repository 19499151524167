import {Link} from "react-router-dom";
import i18n from "i18next";
import LogoImage from "assets/logo.png";
import {RxDashboard} from "react-icons/rx";
import {CgHeadset, CgUserList} from "react-icons/cg";
import {PiExamLight, PiStudent} from "react-icons/pi";
import {BsWallet} from "react-icons/bs";
import {AiOutlineCustomerService, AiOutlineSchedule, AiOutlineShopping} from "react-icons/ai";
import {BiSpreadsheet} from "react-icons/bi";
import {LiaFileContractSolid, LiaUniversitySolid} from "react-icons/lia";
import {MdOutlineHeadphones, MdOutlineManageAccounts} from "react-icons/md";
import {useAuth} from "hooks";
import {CiHeadphones, CiLogout} from "react-icons/ci";
import {RiGroupLine, RiHeadphoneLine} from "react-icons/ri";
import {IoIosGlobe} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {useLocalStorage} from "hooks";
import {TfiHeadphoneAlt} from "react-icons/tfi";
import {FaCode} from "react-icons/fa";
import {GiHamburgerMenu} from "react-icons/gi";
import {useState} from "react";

export function Sidebar() {
    const {user, logout} = useAuth();
    const {t} = useTranslation(["sidebar"]);
    const [lng, setLng] = useLocalStorage("lng", "en");
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const onChangeLang = () => {
        const currentLn = i18n.resolvedLanguage;
        if (currentLn === "ko") {
            i18n.changeLanguage("en");
            setLng("en");
        } else if (currentLn === "en") {
            i18n.changeLanguage("ko");
            setLng("ko");
        }
    }

    return (
        <header className="page-header">
            <nav className={mobileMenuOpen && "mob-menu-opened"}>
                <button className="toggle-mob-menu" aria-expanded="false" aria-label="open menu" onClick={() => setMobileMenuOpen((prev) => !prev)}>
                    <GiHamburgerMenu/>
                </button>
                <Link to={"/"} className="logo">
                    <img src={LogoImage} alt={"Test Qube Logo"} />
                </Link>
                <ul className="admin-menu list-style-none">
                    <li>
                        <Link to={"/"}>
                            <RxDashboard/>
                            <span>{t("sidebar:dashboard")}</span>
                        </Link>
                    </li>
                    <li className={"menu-heading"}>
                        <h3>{t("sidebar:store_section_title")}</h3>
                    </li>
                    <li>
                        <Link to={"/products"}>
                            <AiOutlineShopping/>
                            <span>{t("sidebar:product")}</span>
                        </Link>
                        <Link to={"/purchases"}>
                            <BsWallet />
                            <span>{t("sidebar:purchase")}</span>
                        </Link>
                    </li>
                    <li className={"menu-heading"}>
                        <h3>{t("sidebar:customer")}</h3>
                    </li>
                    <li>
                        <Link to={"/users"}>
                            <CgUserList/>
                            <span>{t("sidebar:user")}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/tests"}>
                            <AiOutlineCustomerService />
                            <span>{t("sidebar:qna")}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/test_results"}>
                            <BiSpreadsheet/>
                            <span>{t("sidebar:test_results")}</span>
                        </Link>
                    </li>
                    <li className={"menu-heading"}>
                        <h3>{t("sidebar:system")}</h3>
                    </li>
                    <li>
                        <Link to={"/test_sheets"}>
                            <FaCode/>
                            <span>{t("sidebar:log")}</span>
                        </Link>
                    </li>
                    <li>
                        {/*<div className="switch">*/}
                        {/*    <input type="checkbox" id="mode" checked/>*/}
                        {/*        <label htmlFor="mode">*/}
                        {/*            <span></span>*/}
                        {/*            <span>Dark</span>*/}
                        {/*        </label>*/}
                        {/*</div>*/}
                        {/*<button className="collapse-btn" aria-expanded="true" aria-label="collapse menu">*/}
                        {/*    <svg aria-hidden="true" />*/}
                        {/*    <span>Collapse</span>*/}
                        {/*</button>*/}
                        <button onClick={() => onChangeLang()}><IoIosGlobe />{t("sidebar:change_language")}</button>
                        <button onClick={() => logout()}><CiLogout/>Logout</button>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
