import {useTranslation} from "react-i18next";
import {DataTable} from "components/DataTable";
import {requestTestResultList} from "apis";
import {useNavigate} from "react-router-dom";

export const TestResultListPage = () => {
    const {t} = useTranslation(["page"]);
    const navigate = useNavigate();

    const Header = (
        <article className={"fluid"} key={"page_testresultlist-section_header"}>
            <h1>{t("page:test_results")}</h1>
        </article>
    );

    const TestResultList = (
        <article className={"fluid"} key={"page_testresultlist-section_testresultlist"}>
            <DataTable
                fetchApi={requestTestResultList}
                formatters={{
                    [t("page:email")]: (data) => data.user.email,
                    [t("page:name")]: (data) => `${data.user.first_name} ${data.user.last_name}`,
                    [t("page:test")]: (data) => `${data.test_set_version.test_set.name} (v${data.test_set_version.seq})`,
                    [t("page:score")]: (data) => data.scores.reduce((accum, currentValue) => accum + currentValue.section_score, 0),
                    [t("page:taken_date")]: (data) => data.created_at,
                }}
                onDataClick={(data) => navigate(`/test_results/${data.uid}`)}
                keyName={"uid"}
            />
        </article>
    );

    return [Header, TestResultList];
}
