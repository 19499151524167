import axios from "axios";

export async function requestPurchaseList() {
    const response = await axios.get("/office/purchases/");
    return response.data;
}

export const requestPurchaseCreate = async (userUid, productUid, description) => {
    const response = await axios.post("/office/purchases/", {user: userUid, product: productUid, description: description});
    return response.data;
}

export async function requestPurchaseDetail(uid) {
    const response = await axios.get(`/office/purchases/${uid}/`);
    return response.data;
}

export const requestPurchaseCancel = async (uid) => {
    const response = await axios.delete(`/office/purchases/${uid}/`);
    return response.data;
}
