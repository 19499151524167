import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "hooks";
import {requestDashboardProductRank, requestDashboardSalesTrendDaily, requestDashboardSummary} from "apis";
import {PreviewLineChart} from "components/PreviewLineChart";

export function MainPage() {
    const {user} = useAuth();
    const { t }  = useTranslation(['page']);
    const [summary, setSummary] = useState(null);
    const [salesTrend, setSalesTrend] = useState([]);
    const [productRank, setProductRank] = useState([]);

    useEffect(() => {
        (async () => {
            const data = await requestDashboardSummary();
            setSummary(data);
            const salesTrendData = await requestDashboardSalesTrendDaily();
            setSalesTrend(salesTrendData);
            const productRankData = await requestDashboardProductRank();
            setProductRank((productRankData));
        })();
    }, [user]);

    const NoInformation = (
        <p>No Available Information</p>
    );

    const Header = (
        <div className={"fluid"} key={"page_main-section_header"}>
            <h1>{t("page:dashboard")}</h1>
        </div>
    );

    const DailySold = (
        <article key={"page_main-section_dailysold"}>
            <header>
                <h1>{t("page:daily_sold")}</h1>
            </header>
            <div className={"mt-3"}>
                {summary ? (
                    <p>{summary.daily_sold}</p>
                ) : NoInformation}
            </div>
        </article>
    );

    const MonthlySold = (
        <article key={"page_main-section_monthlysold"}>
            <header>
                <h1>{t("page:monthly_sold")}</h1>
            </header>
            <div className={"mt-3"}>
                {summary ? (
                    <p>{summary.monthly_sold}</p>
                ) : NoInformation}
            </div>
        </article>
    );

    const UserCount = (
        <article key={"page_main-section_usercount"}>
            <header>
                <h1>{t("page:user_count")}</h1>
            </header>
            <div className={"mt-3"}>
                {summary ? (
                    <p>{summary.user_count}</p>
                ) : NoInformation}
            </div>
        </article>
    )

    const SalesTrend = (
        <article className={"half"} key={"page_main-section_salestrend"}>
            <header>
                <h1>{t("page:sales_trend")}</h1>
            </header>
            <div className={"mt-3"} style={{ height: 150 }}>
                <PreviewLineChart
                    data={salesTrend}
                />
            </div>
        </article>
    )

    const PopularProduct = (
        <article className={"half"} key={"page_main-section_popularproduct"}>
            <header>
                <h1>{t("page:popular_product")}</h1>
            </header>
            <div className={"mt-3"}>
                <table className={"data-table"}>
                    <thead>
                    <tr>
                        <th><p className={"text-left"}>{t("page:ranking")}</p></th>
                        <th><p className={"text-left"}>{t("page:product_name")}</p></th>
                    </tr>
                    </thead>
                    <tbody>
                    {productRank && productRank.map((rank, idx) => (
                        <tr>
                            <td><p className={"text-left"}>{idx + 1}</p></td>
                            <td><p className={"text-left"}>{rank.name}</p></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </article>
    )

    return [Header, DailySold, MonthlySold, UserCount, SalesTrend, PopularProduct];
}
