import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {requestProductDetail} from "../../apis";
import {DataPropertyView} from "../../components/DataProperty";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";

export const ProductDetailPage = () => {
    const {uid} = useParams();
    const {t} = useTranslation(["page"]);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        (async () => {
            const data = await requestProductDetail(uid);
            setProduct(data);
        })();
    }, [uid]);

    const Header = (
        <article className={"fluid"} key={"page_productdetail-section_header"}>
            <header>
                <h1>{t("page:product_detail")}</h1>
                <small>{product?.name}</small>
            </header>
        </article>
    );

    const PropertyView = (
        <article className={"half"} key={"page_productdetail-section_propertyview"}>
            <header>
                <h1>Property</h1>
            </header>
            <div className={"mt-3"}>
                <DataPropertyView
                    data={product}
                    formatters={{
                        [t("page:name")]: data => data.name,
                        [t("page:original_price")]: data => data.original_price,
                        [t("page:price")]: data => data.price,
                        [t("page:test_set_count")]: data => data.test_sets.length,
                        [t("page:activated")]: (data) => data.is_active ? (
                            <div className={"d-flex flex-row align-items-center text-success"}>
                                <FaCircleCheck/>
                            </div>
                        ) : (
                            <div className={"d-flex flex-row align-items-center text-danger"}>
                                <MdCancel/>
                            </div>
                        ),
                        [t("page:created_at")]: data => new Date(data.created_at).toLocaleString(),
                        [t("page:updated_at")]: data => new Date(data.updated_at).toLocaleString(),
                    }}
                />
            </div>
        </article>
    );

    const TestSetList = (
        <article className={"half"} key={"page_productdetail-section_testsetlist"}>
            <header>
                <h1>{t("page:product_test_set")}</h1>
            </header>
            <div className={"mt-3"}>
                <table className={"data-table"}>
                    <thead>
                        <tr>
                            <th>{t("page:name")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {product && product.test_sets.map((test_set) => <tr><td>{test_set.name}</td></tr>)}
                    </tbody>
                </table>
            </div>
        </article>
    );

    return [Header, product && PropertyView, product && TestSetList];
}