import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";
import {requestPurchaseCancel, requestPurchaseDetail} from "apis";
import {DataPropertyView} from "components/DataProperty";

export const PurchaseDetailPage = () => {
    const {uid} = useParams();
    const {t} = useTranslation(["page"]);
    const [purchase, setPurchase] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const data = await requestPurchaseDetail(uid);
            setPurchase(data);
        })();
    }, [uid]);

    const cancelPurchase = async () => {
        const {confirm} = window;
        if (confirm(t("page:delete_confirm"))) {
            const data = await requestPurchaseCancel(uid);
            setPurchase(data);
            alert(t("page:delete_success"));
        }
    }

    const Header = (
        <article className={"fluid"} key={"page_purchasedetail-section_header"}>
            <header className={"d-flex flex-row justify-content-space-between"}>
                <div>
                    <h1>{t("page:purchase_detail")}</h1>
                    <small>{purchase?.merchant_uid}</small>
                </div>
                <div className={"actions"}>
                    {!purchase?.cancelled && (
                        <div>
                            <button className={"btn-danger"} onClick={cancelPurchase}>{t("page:cancel")}</button>
                        </div>
                    )}
                    <div><button className={"btn-info"}>{t("page:update")}</button></div>
                    <div>
                        <button className={"btn-gray"} onClick={() => navigate("/purchases")}>{t("page:back_to_list")}</button>
                    </div>
                </div>
            </header>
        </article>
    );

    const PropertyView = (
        <article className={"half"} key={"page_purchasedetail-section_propertyview"}>
            <header>
                <h1>Property</h1>
            </header>
            <div className={"mt-3"}>
                <DataPropertyView
                    data={purchase}
                    formatters={{
                        [t("page:email")]: (data) => data.user.email,
                        [t("page:name")]: (data) => `${data.user.first_name} ${data.user.last_name}`,
                        [t("page:product")]: (data) => data.product.name,
                        [t("page:merchant_uid")]: (data) => data.merchant_uid,
                        [t("page:order_id")]: (data) => data.order_id,
                        [t("page:cancelled")]: (data) => data.cancelled ? (
                            <div className={"d-flex flex-row align-items-center text-success"}>
                                <FaCircleCheck/>
                            </div>
                        ) : (
                            <div className={"d-flex flex-row align-items-center text-danger"}>
                                <MdCancel/>
                            </div>
                        ),
                        [t("page:created_at")]: (data) => new Date(data.created_at).toLocaleString(),
                        [t("page:updated_at")]: (data) => new Date(data.updated_at).toLocaleString(),
                    }}
                />
            </div>
        </article>
    );

    const Description = (
        <article className={"half"} key={"page_purchasedetail-section_description"}>
            <header>
                <h1>{t("page:description")}</h1>
            </header>
            <div className={"mt-3"}>
                <p>{purchase?.description}</p>
            </div>
        </article>
    );

    return [Header, purchase && PropertyView, purchase && Description];
}