import {useNavigate, useSearchParams} from "react-router-dom";
import {requestCSRFToken, requestLogin} from "apis";
import {useAuth} from "hooks";
import Logo from "assets/logo_blue.png";

export function SignIn() {
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const next = urlSearchParams.get('next');
    const { login } = useAuth();
    requestCSRFToken();

    async function signIn(e) {
        e.preventDefault();
        await requestLogin(e.target);
        await login();
        if (next) {
            navigate(next, { replace: true });
        } else {
            navigate("/", { replace: true });
        }
        return false;
    }

    return (
        <div className={"d-flex flex-column justify-content-center align-items-center page-content w-100 p-3"} style={{ left: 0 }}>
            <form className={"form w-100 mt-3"} onSubmit={signIn} style={{ maxWidth: 320 }}>
                <div className={"position-relative"} style={{ width: "100%", height: 78 }}>
                    <img className={"position-absolute position-0"} src={Logo} width={"100%"} alt={"Logo in login page."} style={{ top: -78, zIndex: 0 }} />
                </div>
                <div className={"form-control-wrapper"} style={{ zIndex: 10 }}>
                    <label htmlFor={"id_username"}>Email</label>
                    <input className={"form-control"} type={"email"} id={"id_username"} name={"username"} required />
                </div>
                <div className={"form-control-wrapper"} style={{ zIndex: 10 }}>
                    <label htmlFor={"id_password"}>Password</label>
                    <input className={"form-control"} type={"password"} id={"id_password"} name={"password"} required />
                </div>
                <div>
                    <button className={"btn-primary w-100 mt-3"} type={"submit"}>Sign In</button>
                </div>
            </form>
        </div>
    )
}
