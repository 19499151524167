import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

axios.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (error) => {
        if (!error.response) {
            console.error(`Server Error (500)`);
        } else if (error.response.status === 403) {
            console.error("Forbidden (403)");
        } else if (error.response.status === 401) {
            console.error("Not Authorized (401)");
        }
        alert(`${error.response.status} : ${Object.values(error.response.data).join(", ")}`);
        return Promise.reject(error);
    }
);
