import axios from "axios";

export async function requestCSRFToken() {
    const response = await axios.get("/auth/set-csrf-cookie/");
    return response.data;
}

export async function requestLogin(form) {
    const response = await axios.post("/auth/login/", new FormData(form));
    return response.data;
}

export async function requestLogout() {
    const response = await axios.post("/auth/logout/");
    return response.data;
}

export async function requestMyInformation() {
    const response = await axios.get("/auth/me/");
    return response.data;
}
