import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {requestPurchaseList} from "apis";
import {DataTable} from "components/DataTable";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";

export const PurchaseListPage = () => {
    const {t} = useTranslation(["page"]);
    const navigate = useNavigate();

    const Header = (
        <article className={"fluid"} key={"page_purchaselist-section_header"}>
            <header className={"d-flex flex-row justify-content-space-between"}>
                <h1>{t("page:manage_purchases")}</h1>
                <div className={"actions"}>
                    <div><button className={"btn-primary"} onClick={() => navigate("/purchases/create")}>{t("page:create_purchase")}</button></div>
                </div>
            </header>
        </article>
    );

    const PurchaseList = (
        <article className={"fluid"} key={"page_purchaselist-section_purchaselist"}>
            <DataTable
                fetchApi={requestPurchaseList}
                formatters={{
                    [t("page:email")]: (data) => data.user.email,
                    [t("page:name")]: (data) => `${data.user.first_name} ${data.user.last_name}`,
                    [t("page:product")]: (data) => data.product.name,
                    [t("page:merchant_uid")]: (data) => data.merchant_uid,
                    [t("page:order_id")]: (data) => data.order_id,
                    [t("page:cancelled")]: (data) => data.cancelled ? (
                        <div className={"d-flex flex-row justify-content-center align-items-center text-success"}>
                            <FaCircleCheck/>
                        </div>
                    ) : (
                        <div className={"d-flex flex-row justify-content-center align-items-center text-danger"}>
                            <MdCancel/>
                        </div>
                    ),
                    [t("page:created_at")]: (data) => data.created_at,
                }}
                onDataClick={(data) => navigate(`/purchases/${data.uid}`)}
                keyName={"uid"}
            />
        </article>
    );

    return [Header, PurchaseList];
}
