import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {DataPropertyView} from "../../components/DataProperty";
import {useParams} from "react-router-dom";
import {requestUserDetail} from "../../apis";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";

export const UserDetailPage = () => {
    const {uid} = useParams();
    const {t} = useTranslation(["page"]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        (async () => {
            const data = await requestUserDetail(uid);
            setUser(data);
        })();
    }, [uid]);

    const Header = (
        <article className={"fluid"} key={"page_userdetail-section_header"}>
            <header>
                <h1>{t("page:user_detail")}</h1>
                <small>{user?.first_name} {user?.last_name}</small>
            </header>
        </article>
    );

    const PropertyView = (
        <article className={"half"} key={"page_userdetail-section_propertyview"}>
            <header>
                <h1>{t("page:property_view")}</h1>
            </header>
            <div className={"mt-3"}>
                <DataPropertyView
                    data={user}
                    formatters={{
                        [t("page:email")]: (data) => data.email,
                        [t("page:name")]: (data) => `${data.first_name} ${data.last_name}`,
                        [t("page:is_active")]: (data) => data.is_active ? (
                            <div className={"d-flex flex-row align-items-center text-success"}>
                                <FaCircleCheck />
                            </div>
                        ) : (
                            <div className={"d-flex flex-row align-items-center text-danger"}>
                                <MdCancel />
                            </div>
                        ),
                        [t("page:date_joined")]: (data) => data.date_joined,
                        [t("page:last_login")]: (data) => data.last_login,
                    }}
                />
            </div>
        </article>
    );

    return [Header, user && PropertyView];
}
