import {useState} from "react";
import {useTranslation} from "react-i18next";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";
import {requestProductList, requestPurchaseCreate, requestUserList} from "apis";
import {DataTable} from "components/DataTable";
import {useNavigate} from "react-router-dom";

export const PurchaseCreatePage = () => {
    const {t} = useTranslation(["page"]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [description, setDescription] = useState("");
    const navigate = useNavigate();

    const createPurchase = async () => {
        if (!selectedUser) {
            alert("Must select one user.");
            return
        }
        if (!selectedProduct) {
            alert("Must select one product.");
            return
        }
        const data = await requestPurchaseCreate(selectedUser.uid, selectedProduct.uid, description);
        navigate(`/purchases/${data.uid}`);
    }

    const Header = (
        <article className={"fluid position-sticky"} key={"page_productcreate-section_header"}>
            <header className={"d-flex flex-row justify-content-space-between"}>
                <h1>{t("page:create_purchase")}</h1>
                <div className={"actions"}>
                    <div><button className={"btn-success"} onClick={createPurchase}>{t("page:create")}</button></div>
                    <div><button className={"btn-danger"} onClick={() => navigate("/purchases")}>{t("page:cancel")}</button></div>
                </div>
            </header>
        </article>
    );

    const UserSelectForm = (
        <article className={"half"}>
            <header>
                <h1>{t("page:select_user")}</h1>
            </header>
            <div className={"mt-3"}>
                <DataTable
                    fetchApi={requestUserList}
                    formatters={{
                        [t("page:email")]: (data) => data.email,
                        [t("page:name")]: (data) => `${data.first_name} ${data.last_name}`,
                        [t("page:is_active")]: (data) => data.is_active ? (
                            <div className={"d-flex flex-row justify-content-center align-items-center text-success"}>
                                <FaCircleCheck/>
                            </div>
                        ) : (
                            <div className={"d-flex flex-row justify-content-center align-items-center text-danger"}>
                                <MdCancel/>
                            </div>
                        ),
                        [t("page:date_joined")]: (data) => data.date_joined,
                        [t("page:last_login")]: (data) => data.last_login,
                    }}
                    onDataClick={(data) => setSelectedUser(data)}
                    onDataSelect={(selections) => {
                        const selectedList = [...selections];
                        if (selectedList.length === 1) {
                            setSelectedUser(selectedList[0])
                        } else if (selectedList.length > 1) {
                            alert("Must select one user.")
                        }
                    }}
                    keyName={"uid"}
                />
            </div>
        </article>
    );

    const UserSelectedView = (
        <div className={"half"} key={"page_purchasecreate-section_userselectedview"}>
            <article>
                <header>
                    <h1>{t("page:select_user")}</h1>
                </header>
                <div className={"mt-3"}>
                    <div className={"d-flex flex-row justify-content-space-between"}>
                        <b>Selection Complete</b>
                        <p>{selectedUser?.email} | {selectedUser?.first_name} {selectedUser?.last_name}</p>
                    </div>
                    <div className={"mt-3"}>
                        <button className={"btn-danger w-100"} onClick={() => setSelectedUser(null)}>Reselect</button>
                    </div>
                </div>
            </article>
        </div>
    );

    const ProductSelectForm = (
        <article className={"half"} key={"page_purchasecreate-section_productselectform"}>
            <header>
                <h1>{t("page:select_product")}</h1>
            </header>
            <div className={"mt-3"}>
                <DataTable
                    fetchApi={requestProductList}
                    formatters={{
                        [t("page:name")]: (data) => data.name,
                        [t("orginal_price")]: (data) => data.original_price,
                        [t("sales_price")]: (data) => data.sales_price,
                        [t("test_set_count")]: data => data.test_sets.length,
                        [t("created_at")]: (data) => data.created_at,
                    }}
                    onDataClick={(data) => setSelectedProduct(data)}
                    onDataSelect={(selections) => {
                        const selectedList = [...selections];
                        if (selectedList.length === 1) {
                            setSelectedProduct(selectedList[0])
                        } else if (selectedList.length > 1) {
                            alert("Must select one product.")
                        }
                    }}
                    keyName={"uid"}
                />
            </div>
        </article>
    );

    const ProductSelectedView = (
        <div className={"half"} key={"page_purchasecreate-section_productselectedview"}>
            <article>
                <header>
                    <h1>{t("page:select_product")}</h1>
                </header>
                <div className={"mt-3"}>
                    <div className={"d-flex flex-row justify-content-space-between"}>
                        <b>Selection Complete</b>
                        <p>{selectedProduct?.name}</p>
                    </div>
                    <div className={"mt-3"}>
                        <button className={"btn-danger w-100"} onClick={() => setSelectedProduct(null)}>Reselect</button>
                    </div>
                </div>
            </article>
        </div>
    );

    const DescriptionInputForm = (
        <article className={"fluid"}>
            <header>
                <h1>{t("page:description")}</h1>
            </header>
            <div className={"form-control-wrapper mt-3"}>
                <textarea className={"fluid"} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
        </article>
    );

    return [Header, selectedUser ? UserSelectedView : UserSelectForm, selectedProduct ? ProductSelectedView : ProductSelectForm, DescriptionInputForm];
}
