import React, {useEffect, useState} from "react";
import "./data-table.css";

export function Table({dataset, formatters, keyName, onDataClick, totalCount, loadMore=false, onLoadMore, onSelect}) {
    const [selectedList, setSelectedList] = useState(new Set([]));
    const dataKeys = Object.keys(formatters);

    useEffect(() => {
        if (onSelect)
            onSelect(selectedList);
    }, [selectedList, onSelect]);

    function renderTableHeader() {
        return (
            <tr>
                <th>
                    <input
                        type={"checkbox"}
                        name={"checkcheck"}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedList(new Set(dataset.map((data) => data)));
                            } else {
                                setSelectedList(new Set([]));
                            }
                        }}
                        checked={selectedList.size > 0 && selectedList.size === dataset.length}
                    />
                </th>
                {dataKeys.map((dataKey) => <th key={dataKey}>{dataKey.split("_").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ")}</th>)}
            </tr>
        )
    }

    function renderData() {
        return dataset.map((data) => (
            <tr  key={data[keyName]} key={data[keyName]}>
                <th>
                    <input
                        type={"checkbox"}
                        value={data[keyName]}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedList(prev => new Set(prev.add(data)));
                            } else {
                                setSelectedList(prev => {
                                    prev.delete(data);
                                    return new Set(prev);
                                });
                            }
                        }}
                        checked={selectedList.has(data)}
                    />
                </th>
                {dataKeys.map((dataKey) => (
                    <td key={`${data[keyName]}_${dataKey}`} onClick={() => onDataClick(data)}>{formatters[dataKey](data) ? formatters[dataKey](data) : "-"}</td>
                ))}
            </tr>
        ))
    }

    return (
        <div>
            <div className={""}>
                <input type={"text"} placeholder={"Search"} readOnly />
            </div>
            <div className={"data-table-wrapper"}>
                <table className={"data-table mt-3"}>
                    <thead>
                    {renderTableHeader()}
                    </thead>
                    <tbody>
                    {renderData()}
                    </tbody>
                    {loadMore && (
                        <tfoot>
                            <tr>
                                <td colSpan={dataKeys.length + 1}>
                                    <button id={"btn-load-more"} className={"btn-primary"} onClick={onLoadMore}>Load More</button>
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </table>
            </div>
            <p className={"mt-3 text-right"}>
                { selectedList.size > 0 && <span>Selected {selectedList.size}, </span>}
                { loadMore && <span>Loaded {dataset.length} of </span>}
                <span>Total {totalCount}</span>
            </p>
        </div>
    )
}
