import axios from "axios";

export const requestDashboardSummary = async () => {
    const response = await axios.get("/office/dashboard/summary/");
    return response.data;
}

export const requestDashboardProductRank = async () => {
    const response = await axios.get("/office/dashboard/product_rank/");
    return response.data;
}

export const requestDashboardSalesTrendDaily = async () => {
    const response = await axios.get("/office/dashboard/sales_trend/daily/");
    return response.data;
}
