import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Layout, ProtectedRoute} from "./components";
import {
    MainPage,
    Page404,
    SignIn,
    UserDetailPage,
    UserListPage,
    ProductListPage,
    ProductDetailPage,
    PurchaseListPage,
    PurchaseDetailPage,
    PurchaseCreatePage,
    TestResultListPage, TestResultDetailPage,
} from "./pages";
import {AuthProvider} from "./hooks";
function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={(
                        <ProtectedRoute>
                            <Layout/>
                        </ProtectedRoute>
                    )}>
                        <Route index element={<MainPage/>}/>
                        <Route path={"users"}>
                            <Route index element={<UserListPage/>}></Route>
                            <Route path={":uid"} element={<UserDetailPage/>}></Route>
                        </Route>
                        <Route path={"products"}>
                            <Route index element={<ProductListPage/>}/>
                            <Route path={":uid"} element={<ProductDetailPage/>}/>
                        </Route>
                        <Route path={"purchases"}>
                            <Route index element={<PurchaseListPage/>}/>
                            <Route path={"create"} element={<PurchaseCreatePage/>}/>
                            <Route path={":uid"} element={<PurchaseDetailPage/>}/>
                        </Route>
                        <Route path={"test_results"}>
                            <Route index element={<TestResultListPage/>} />
                            <Route path={":uid"} element={<TestResultDetailPage/>}/>
                        </Route>
                        <Route path={"*"} element={<Page404/>}/>
                    </Route>
                    <Route path={"sign_in"} element={<SignIn/>}/>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
