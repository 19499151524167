import axios from "axios";

export const requestTestResultList = async () => {
    const response = await axios.get("/office/test_results/");
    return response.data;
}

export const requestTestResultDetail = async (testSheetUid) => {
    const resposne = await axios.get(`/office/test_results/${testSheetUid}/`);
    return resposne.data;
}
