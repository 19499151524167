import {useEffect, useState} from "react";
import {Table} from "./Table";
import {getRequest} from "apis";

export const TableWrapper = ({fetchApi, formatters, keyName, onDataClick, onDataSelect}) => {
    const [dataset, setDataset] = useState([]);
    const [next, setNext] = useState({});
    const [totalCount, setTotalCount] = useState(0);

    function loadNext(response) {
        setNext(response.next);
    }

    function loadMore() {
        if (next !== null) {
            (async function () {
                const response = await getRequest(next);
                setDataset((prev => prev.concat(response.results)));
                setTotalCount(response.count);
                loadNext(response);
            })();
        }
    }

    useEffect(function () {
        (async function () {
            const response = await fetchApi();
            setDataset(response.results);
            setTotalCount(response.count);
            loadNext(response);
        })();
    }, [fetchApi]);

    return (
        <Table
            dataset={dataset}
            formatters={formatters}
            keyName={keyName}
            onDataClick={onDataClick}
            totalCount={totalCount}
            loadMore={next !== null}
            onLoadMore={loadMore}
            onSelect={onDataSelect}
        />
    )
}
