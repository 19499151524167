import axios from "axios";

export async function requestProductList() {
    const resposne = await axios.get("/office/products/");
    return resposne.data;
}

export async function requestProductDetail(uid) {
    const response = await axios.get(`/office/products/${uid}/`);
    return response.data;
}
