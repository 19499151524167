import {Outlet} from "react-router-dom";
import {Sidebar} from "./Sidebar";
import {Navbar} from "./Navbar";
import {Footer} from "./Footer";

export function Layout() {
    return (
        <div>
            <Sidebar/>
            <div className="page-content">
                <Navbar/>
                <section className="grid">
                    <Outlet />
                </section>
                <Footer/>
            </div>
        </div>
    )
}
