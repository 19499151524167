import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {requestTestResultDetail} from "apis";
import {useNavigate, useParams} from "react-router-dom";
import {DataPropertyView} from "../../components/DataProperty";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";

export const TestResultDetailPage = () => {
    const {uid} = useParams();
    const {t} = useTranslation(["page"]);
    const [testResult, setTestResult] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const data = await requestTestResultDetail(uid);
            setTestResult(data);
        })();
    }, []);

    const Header = (
        <article className={"fluid"} key={"page_testresultdetail-section_header"}>
            <header className={"d-flex flex-row justify-content-space-between align-items-center"}>
                <div>
                    <h1>{t("page:test_detail")}</h1>
                    <small>{testResult?.test_sheet.test_set_version.test_set.name}</small>
                </div>
                <div className={"actions"}>
                    <div>
                        <button className={"btn-gray"} onClick={() => navigate("/test_results")}>Back To List</button>
                    </div>
                </div>
            </header>
        </article>
    );

    const PropertyView = (
        <article className={"half"} key={"page_testresultdetail-section_propertyview"}>
            <header>
                <h1>{t("page:property")}</h1>
            </header>
            <div className={"mt-3"}>
                <DataPropertyView
                    data={testResult?.test_sheet}
                    formatters={{
                        [t("page:user")]: data => `${data.user.first_name} ${data.user.last_name}`,
                        [t("page:email")]: data => data.user.email,
                        [t("page:test_set_name")]: data => data.test_set_version.test_set.name,
                        [t("page:scores")]: data => (
                            <div>
                                {data.scores.map((score) => (
                                    <p key={`${testResult.test_sheet.uid}_${score.name}`}>{score.name} : {score.section_score}</p>
                                ))}
                            </div>
                        ),
                        [t("page:created_at")]: data => new Date(data.created_at).toLocaleString(),
                    }}
                />
            </div>
        </article>
    );

    const SubmissionList = (
        <article className={"half"} key={"page_testresultdetail-section_submissionlist"}>
            <header>
                <h1>{t("page:submissions")}</h1>
            </header>
            <div className={"mt-3"}>
                {testResult?.submissions.map(submission => (
                    <div key={submission.uid}>
                        <h3>{submission.module.section.name}</h3>
                        <table className={"w-100 mt-3"} style={{borderCollapse: "collapse"}}>
                            <thead>
                            <tr className={"bg-dark text-white"}>
                                <th className={"border-black p-2"} style={{ borderRadius: 0}}>#</th>
                                <th className={"border-black p-2"} style={{ borderRadius: 0}}>{t("page:correct_answer")}</th>
                                <th className={"border-black p-2"} style={{ borderRadius: 0}}>{t("page:user_response")}</th>
                                <th className={"border-black p-2"} style={{ borderRadius: 0}}>{t("page:result")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {submission.responses.map(response => (
                                <tr className={"border-black"} key={response.uid}>
                                    <td className={"border-black"} style={{ borderRadius: 0}}>{response.question_set.seq}</td>
                                    <td className={"border-black"} style={{ borderRadius: 0}}>{response.question_set.answer.toUpperCase()}</td>
                                    <td className={"border-black"} style={{ borderRadius: 0}}>{response.response === "" ? "-" : response.response.toUpperCase()}</td>
                                    <td className={"border-black"} style={{ borderRadius: 0}}>
                                        {response.question_set.answer.toUpperCase().split(",").includes(response.response.toUpperCase()) ? (
                                            <div className={"d-flex flex-row justify-content-center align-items-center text-success"}>
                                                <FaCircleCheck/>
                                            </div>
                                        ) : (
                                            <div className={"d-flex flex-row justify-content-center align-items-center text-danger"}>
                                                <MdCancel/>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </article>
    );

    return [Header, testResult && PropertyView, SubmissionList];
}