import axios from "axios";

export async function requestUserList(limit = "", offset = "") {
    const response = await axios.get(`/office/users/?limit=${limit}&offset=${offset}`);
    return response.data;
}

export async function requestUserDetail(uid) {
    const response = await axios.get(`/office/users/${uid}/`);
    return response.data;
}
